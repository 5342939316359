import React from 'react';
import styled from 'styled-components';
import { vars } from '../../../lib/theme';

const StyledHelp = styled('div')`
  font-size: 0.8em;
  line-height: 1.2;
  color: ${vars.colors.grey.dark};
  margin-bottom: ${vars.spacing[2]};
`;

const Help = ({ children }) => <StyledHelp>{children}</StyledHelp>;

export default Help;
