import React from 'react';
import styled from 'styled-components';

import { vars } from '../../../lib/theme';

const Label = ({ htmlFor, error, children }) => (
  <StyledLabel htmlFor={htmlFor} error={error}>
    {children}
  </StyledLabel>
);

export default Label;

// Styles

const StyledLabel = styled('label')`
  font-weight: 500;
  display: block;
  margin-bottom: ${vars.spacing[0]};
  font-size: 0.8rem;
`;
