import React from 'react';
import styled from 'styled-components';
import { vars } from '../../../lib/theme';

const StyledError = styled('div')`
  font-weight: 500;
  font-size: 0.8em;
  text-align: left;
  margin-top: ${vars.spacing[1]};
  color: ${vars.colors.warning.mid};
`;

const Error = ({ className, children }) => <StyledError className={className}>{children}</StyledError>;

export default Error;
