import React from 'react';
import styled from 'styled-components';

import Label from '../Label';
import Help from '../Help';
import Error from '../Error';

const InputText = ({ className, type, id, label, help, error, value, onChange, onBlur, ...props }) => {
  return (
    <Root className={className}>
      {label && (
        <Label htmlFor={id} error={error}>
          {label}
        </Label>
      )}
      {help && <Help>{help}</Help>}
      <input id={id} type={type} value={value || ''} onChange={onChange} onBlur={onBlur} {...props} />
      {error && <Error>{error}</Error>}
    </Root>
  );
};

export default InputText;

// Styles

const Root = styled('div')`
  text-align: left;
`;
